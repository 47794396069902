import React, { FC, useContext, useState, useEffect } from 'react';
import useSWR from 'swr';

import { useTranslations } from '../../hooks/use-translations';

import { useNavigate, useParams } from 'react-router-dom';
import FormStatusMenu from '../../components/form-status-menu/form-status-menu';

import CaseProcess from '../../components/case-process/case-process';
import { Recommendation } from '../../models/statuspage/recommendation';
import Loader from '../../components/loader/loader';
import { Message } from '../../models/statuspage/message';
import { postSeenByRecipient } from '../../apis/seen-by-recipient';
import UXSignalsWidget from '../../components/UX-signals-widget/UX-signals-widget';
import Communication from '../../components/messages/communication';
import { AppContext } from '../../context/app-context';
import { Language } from '../../models';
import Page from '../../components/page/page';
import './status-page.scss';
import { getSanityCaseProcesseByParams } from '../../apis/get-sanity-case-process';
import { SanityCaseProcessStep } from '../../models/sanity-case-process';
import { convertFormDates, findActiveStep, getCaseProcess, mapStepsWithDate, modifySanityCaseProcesses } from '../../services/caseProcessService';
import { FormSummary } from '../../components/Forms/FormSummary';
import { ApplicationStatus } from '../../models/statuspage/application-status';
import { getForm } from '../../apis/get-form';
import { FormActionTypes, FormSaveState } from '../../context/form-reducer';
import formSanitizer from '../../utils/formSanitizer';
import Referralparties from '../../components/referralparties/referralparties';

const StatusPage: FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const { translations } = useTranslations();
  const { formId, index } = useParams<{ formId: string; index: string }>();
  const [form, setForm] = useState<any>(null);

  useEffect(() => {
    if (formId)
      getForm(formId).then((data) => {
        setForm(data);
        dispatch({ type: FormActionTypes.SetForm, payload: formSanitizer(data) });
        dispatch({ type: FormActionTypes.SetSaveState, payload: FormSaveState.Saved });
      });
  }, [formId, dispatch]);

  const { data: sanityCaseProcesses } = useSWR(
    () => 'sanityCaseProcess',
    () => getSanityCaseProcesseByParams('caseFormType.current', form.identifier.split('-')[0]),
    {
      refreshInterval: 15 * 1000,
    }
  );

  const {
    data: caseProcess,
    error,
    mutate,
  } = useSWR(`${formId}`, getCaseProcess, {
    refreshInterval: 5 * 60 * 1000,
  });

  const [unseenMessages, setUnseenMessages] = React.useState<Message[]>();
  const [sanityCaseProcessSteps, setSanityCaseProcessSteps] = React.useState<SanityCaseProcessStep[]>([]);
  const [recommendedCourse, setRecommendedCourse] = React.useState<Recommendation[] | undefined>();
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [activeStep, setActiveStep] = React.useState<number | null>(null);

  const language = state.configuration.language;

  React.useEffect(() => {
    if (sanityCaseProcesses && caseProcess && navigate && formId) {
      caseProcess?.status === ApplicationStatus.Draft && navigate(`/form/${formId}`);
      try {
        const formDates = convertFormDates(caseProcess);
        const modifiedSanityCaseProcesses = modifySanityCaseProcesses(sanityCaseProcesses);
        const steps = mapStepsWithDate(modifiedSanityCaseProcesses, formDates);
        setSanityCaseProcessSteps(steps);
        const activeStep = findActiveStep(caseProcess, steps);
        setActiveStep(activeStep);
      } catch (error) {
        console.error('Error processing form:', error);
      }
    }
  }, [sanityCaseProcesses, caseProcess, navigate, formId]);

  React.useEffect(() => {
    setRecommendedCourse(caseProcess?.recommendations);
    if (caseProcess && caseProcess.messages) {
      const unseenMessages = caseProcess.messages.filter((message: Message) => !message.seenByRecipient);
      setUnseenMessages(unseenMessages);
    }
  }, [caseProcess]);

  React.useEffect(() => {
    if (caseProcess?.title && translations.applicationTitle && translations.companyName) {
      document.title = `${caseProcess.title} - ${translations.applicationTitle} - ${translations.companyName}`;
    }
  }, [translations, caseProcess]);

  React.useEffect(() => {
    if (activeTab === 1 && unseenMessages && formId) {
      unseenMessages.forEach((message) => {
        handleSeenByRecipient(message);
      });
    }
  }, [activeTab, unseenMessages, formId]);

  const handleNavigation = async (index: number) => {
    if (activeTab === 1 && unseenMessages?.length) {
      for (const message of unseenMessages) {
        try {
          await handleSeenByRecipient(message);
          setUnseenMessages((prevMessages) => prevMessages?.filter((m) => m.id !== message.id));
        } catch (error) {
          console.error(`Error marking message ${message.id} as seen:`, error);
        }
      }
    }

    if (activeTab !== index) {
      setActiveTab(index);
      navigate(`/form/${formId}/status/${index}`);
    }
  };

  const handleSeenByRecipient = async (msg: Message) => {
    return await postSeenByRecipient(formId, msg.id);
  };

  // Code to set user attributes for hotjar. Used to filter which users get survey.
  if (caseProcess && caseProcess.recommendations) {
    var hotjarAttributes = Object.assign(
      {},
      ...caseProcess.recommendations.map((r: Recommendation) => ({
        [r.identifier]: caseProcess.recommendations.length > 0 ? true : false,
      })),
      { recommendation: caseProcess.recommendations.length > 0 ? true : false }
    );
    if ((window as any).hj) (window as any).hj('identify', null, hotjarAttributes);
  }

  const municipalityFirstLineCount = caseProcess?.municipalityFirstLine ? 1 : 0;
  const referralparties = (caseProcess?.businessSupportOrganizations?.length || 0) + municipalityFirstLineCount;

  const hasMessages = caseProcess?.messages?.length;
  const featureflagEnabled = state.configuration.featureFlags.consentToReferralpartiesEnabled;
  //Skip Refferalparties tab when there are no messages yet 
  const activeTabNoMessages = activeTab === (hasMessages ? 3 : 2);

  return (
    <>
      {caseProcess?.status !== ApplicationStatus.Draft ? (
        <Page inputs={[caseProcess]} errors={[error]} skipToContent={`form/${formId}/status#maincontent`}>
          <div className="form-status" tabIndex={-1}>
            <div className="form-status__case-process">
              <FormStatusMenu
                formId={formId}
                projectUrl={`form/${formId}`}
                formStatus={caseProcess?.status}
                unseenMessages={unseenMessages}
                messages={caseProcess?.messages}
                selectedMenuElement={handleNavigation}
                activeTab={activeTab}
                referralParties={referralparties}
              />

              {activeTab === 0 && (
                <div>
                  <CaseProcess
                    key={index}
                    caseProcessData={sanityCaseProcessSteps}
                    activeStep={activeStep}
                    recommendations={recommendedCourse}
                    language={language}
                    formWithAttachment={caseProcess}
                    setTab={() => setActiveTab(1)}
                  />

                  {state.configuration.language !== Language.en && caseProcess?.messages && caseProcess.messages.some((m) => m.origin === 'Advisor') && (
                    <div className="form-status__uxsignals">
                      <UXSignalsWidget isReadyToLoadScript={caseProcess !== undefined} id="panel-ilxhy6l9tn" />
                    </div>
                  )}
                </div>
              )}

              {activeTab === 1 && (
                <div className="form-status__communication">
                  <div aria-live="polite">
                    {caseProcess?.messages && caseProcess.messages.length > 0 ? (
                      <Communication caseProcess={caseProcess} onPostMessage={mutate} />
                    ) : caseProcess?.messages === undefined ? (
                      <Loader />
                    ) : (
                      <p>{translations.formStatusTabCommunicationEmpty}</p>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 2 && hasMessages && featureflagEnabled && (
                <Referralparties
                  municipality={(caseProcess.municipalityFirstLine && caseProcess.municipalityFirstLine.name) || ''}
                  bso={caseProcess.businessSupportOrganizations}
                  consent={caseProcess.consent}
                  featureFlag={state.configuration.featureFlags}
                />
              )}

              {activeTabNoMessages && <FormSummary />}
            </div>
          </div>
        </Page>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StatusPage;
